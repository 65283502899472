import {
  Box,
  Flex,
  Grid,
  Skeleton,
} from '@chakra-ui/react';
import React from 'react';
import { useWaitForTransactionReceipt } from 'wagmi';

import config from 'configs/app';
import chain from 'configs/app/chain';
import { fromNow } from 'lib/date/dayjs';
import useIsMobile from 'lib/hooks/useIsMobile';
import BlockEntityL1 from 'ui/shared/entities/block/BlockEntityL1';
import TxEntity from 'ui/shared/entities/tx/TxEntity';
import TxEntityL1 from 'ui/shared/entities/tx/TxEntityL1';
import { L2DepositsItem } from 'types/api/l2Deposits';
import { Console } from 'lib/console';

const feature = config.features.rollup;

type Props = {
  item: L2DepositsItem;
  isLoading?: boolean;
}

const LatestDepositsItem = ({ item, isLoading }: Props) => {
  const timeAgo = fromNow(Number(item.blockTimestamp) * 1000);
  const isMobile = useIsMobile();

  const { data } = useWaitForTransactionReceipt(
    item?.targetTxHash ?
      {
        chainId: Number(chain.id),
        hash: `${ item?.targetTxHash }` as `0x${ string }`,
        // cacheTime: 30_000,
      } :
      {},
  );


  Console.log('data 3', data);
  
  if (!feature.isEnabled) {
    return null;
  }

  const l1BlockLink = (
    <BlockEntityL1
      number={ item.blockNumber }
      isLoading={ isLoading }
      fontSize="sm"
      lineHeight={ 5 }
      fontWeight={ 700 }
    />
  );

  const l1TxLink = (
    <TxEntityL1
      isLoading={ isLoading }
      hash={ item.transactionHash }
      fontSize="sm"
      lineHeight={ 5 }
      truncation={ isMobile ? 'constant_long' : 'dynamic' }
    />
  );

  const l2TxLink = (
    data ? (
      <TxEntity
        isLoading={ isLoading }
        hash={ item.targetTxHash }
        fontSize="sm"
        lineHeight={ 5 }
      />
    ) : '--'
  );

  const content = (() => {
    if (isMobile) {
      return (
        <>
          <Flex justifyContent="space-between" alignItems="center" mb={ 1 }>
            { l1BlockLink }
            <Skeleton isLoaded={ !isLoading } color="text_secondary">
              <span>{ timeAgo }</span>
            </Skeleton>
          </Flex>
          <Grid gridTemplateColumns="56px auto">
            <Skeleton isLoaded={ !isLoading } my="5px" w="fit-content">
              L1 txn
            </Skeleton>
            { l1TxLink }
            <Skeleton isLoaded={ !isLoading } my="3px" w="fit-content">
              L2 txn
            </Skeleton>
            { l2TxLink }
          </Grid>
        </>
      );
    }

    return (
      <Grid width="100%" columnGap={ 4 } rowGap={ 2 } templateColumns="max-content max-content auto" w="100%">
        { l1BlockLink }
        <Skeleton isLoaded={ !isLoading } w="fit-content" h="fit-content" my="5px">
          L1 txn
        </Skeleton>
        { l1TxLink }
        <Skeleton isLoaded={ !isLoading } color="text_secondary" w="fit-content" h="fit-content" my="2px">
          <span>{ timeAgo }</span>
        </Skeleton>
        <Skeleton isLoaded={ !isLoading } w="fit-content" h="fit-content" my="2px">
          L2 txn
        </Skeleton>
        { l2TxLink }
      </Grid>
    );
  })();

  return (
    <Box
      width="100%"
      borderTop="1px solid"
      borderColor="divider"
      py={ 3 }
      px={{ base: 0, lg: 4 }}
      _last={{ borderBottom: '1px solid', borderColor: 'divider' }}
      fontSize="sm"
      lineHeight={ 5 }
    >
      { content }
    </Box>
  );
};

export default React.memo(LatestDepositsItem);
